import { render, staticRenderFns } from "./bindAccount.vue?vue&type=template&id=7ccd4d9e&scoped=true"
import script from "./bindAccount.vue?vue&type=script&lang=js"
export * from "./bindAccount.vue?vue&type=script&lang=js"
import style0 from "./bindAccount.vue?vue&type=style&index=0&id=7ccd4d9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ccd4d9e",
  null
  
)

export default component.exports