<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('bindAccount.default[0]')"
            left-arrow
            @click-left="$router.go(-2)"
    />
    <div class="ScrollBox">
          <van-form @submit="onSubmit">
            <div class="Panel">
                <van-field
                        v-model="BankInfo.name"
                        :label="$t('bindAccount.fields[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                />
                <van-field
                        v-model="BankInfo.phone"
                        :label="$t('bindAccount.fields[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                />
                <van-field
                        v-model="BankInfo.card_no"
                        :label="$t('bindAccount.fields[2]')"
                        :placeholder="$t('bindAccount.placeholder[2]')"
                />
                <van-field
                        v-model="BankInfo.bank_name"
                        :label="$t('bindAccount.fields[3]')"
                        :placeholder="$t('bindAccount.placeholder[3]')"
                />
            </div>
            <div v-show="!canEdit" style="margin-left: 20px;margin-top: 20px">{{$t('busAuth2[4]')}}</div>
            <div style="text-align: center;margin-top: 20px" v-show="canEdit">
              <van-button @click="onSubmit">{{ $t('bindAccount.default[1]') }}</van-button>
            </div>
          </van-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      columns : ['ERC20', 'TRC20', 'OMNI'],
      BankInfo:{},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      canEdit:true,
      tabsList: [],
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()

    this.BankInfo.coin_type = this.columns[0];
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
        this.BankInfo = data['data'][0];
        this.canEdit = false;
      }
      }
    );
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    onSubmit() {
      this.$Model.AddBankCard(this.BankInfo,data=>{
         var msg = "";
         if (data.code==1){
             msg = this.$t('common4[1]');
         }else{
             msg = data.code_dec;
         }
          this.$Dialog.Toast(msg);
      });
    },
    onChangeType(obj,value){
      this.BankInfo.coin_type = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
    .PageBox {

    }
    .PageBox .box {
        width: 95%;
        padding: 10px 0px 10px 10px;
        font-size: 19px;
        margin-top: 15px;
        border-radius: 10px;
        margin-left: 10px;
        background-color: #24303C;
        color: #cccccc;
    }


    .van-field{
        font-weight: bold;
    }

    .btn {
        width: 95%;
        padding: 10px 50px;
        border-radius: 5px;
        background-color: #5652FD;
        color: #fff;
        font-size: 18px;
        text-align: center;
        margin: 15px 10px 30px;
    }
</style>
